// AuthClient

import {client, localStorageKey} from './api-client'

function handleUserResponse({token, user}) {
  window.localStorage.setItem(localStorageKey, token);
  return user
}

function getUser() {
  const token = getToken()
  if (!token) {
    return Promise.resolve(null)
  }
  return client('api/me.json').then(data => data)
}

function getRoles() {
  return client('api/roles.json').then(data => data)
}

// type signInData = {
//   user: {
//     email: string,
//     password: string
//   }
// }

function signIn(data) {
  const user = client('users/sign_in.json', {body: data}).then(handleUserResponse);
  return user;
}

// type SignUpData = {
//   user: {
//     email: string,
//     password: string,
//     password_confirmation: string,
//     first_name: string,
//     last_name: string,
//   },
//   organization: {
//     name: string,
//     subdomain: string,
//     address: String,
//   }
// }

function signUp(data) {
  const userInfo = client('users.json', {body: data}).then((response) => { return response });
  return userInfo;
}

function getToken() {
  return window.localStorage.getItem(localStorageKey)
}

function isLoggedIn() {
  return Boolean(getToken())
}

export { signUp, signIn, getToken, getUser, isLoggedIn, getRoles }
export { signOut } from './api-client'
