// import {queryCache} from 'react-query'
import * as auth from './auth-client'

async function bootstrapAppData() {
  let appData = {user: null, roles: [], orgs: [], currentOrg: null}
  if (auth.isLoggedIn()) {
    const [user] = await Promise.all([
      auth.getUser()
    ])
    const [roles] = await Promise.all([
      auth.getRoles()
    ])
    const subdomain = localStorage.getItem('inven3-subdomain');
    let org = user.organizations[0];
    if (subdomain != null) {
      for (var i = 0; i < user.organizations.length; i++) {
        const a = user.organizations[i];
        if (subdomain === a.subdomain) {
          org = a;
          break;
        }
      }
    }
    appData = {user, roles, orgs: user.organizations, currentOrg: org}
  }
  return appData
}

export {bootstrapAppData}
