import React from 'react'
import {useAuth} from './context/auth-context'
import {FullPageSpinner} from './components/lib'
import {ThemeProvider} from '@material-ui/core/styles';
import theme from './theme'

const AuthenticatedApp = React.lazy(() =>
  import(/* webpackPrefetch: true */ './authenticated-app'),
)
const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'))

function App() {
  const {user} = useAuth()
  return (
    <ThemeProvider theme={theme}>
      <React.Suspense fallback={<FullPageSpinner />}>
        {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </React.Suspense>
    </ThemeProvider>
  )
}

export {App}
