import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#CB7F5F",
      main: "#FCD029",
      dark: "#9D5030",
      contrastText: "#00000"
    },
    secondary: {
      main: "#2955FC"
    },
    honeyOld: {
      light: "#CB7F5F",
      main: "#C0623B",
      dark: "#9D5030",
      contrastText: "#FFFFFF"
    },
  },
});

export default theme
