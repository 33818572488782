/** @jsx jsx */
/** @jsxFrag React.Fragment */
import {jsx} from '@emotion/core'
// import * as _ from 'lodash'
import React from 'react'
import {bootstrapAppData} from 'utils/bootstrap'
import * as authClient from 'utils/auth-client'
import {useAsync} from 'utils/use-async'
import {FullPageSpinner, FullPageErrorFallback} from 'components/lib'

const AuthContext = React.createContext()
AuthContext.displayName = 'AuthContext'

const appDataPromise = bootstrapAppData()

function AuthProvider(props) {
  const {
    data,
    status,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
  } = useAsync()

  React.useLayoutEffect(() => {
    run(appDataPromise)
  }, [run])

  const user = data?.user
  const orgs = data?.user?.organizations
  const currentOrg = data?.currentOrg
  const roles = data?.roles

  const setOrg = React.useCallback(
    (org, user) => {
      window.localStorage.setItem('inven3-subdomain', org.subdomain)
      window.localStorage.setItem('inven3-org-name', org.name)
      authClient.getUser().then(user => {
        // TODO: user.roles is empty after signup. Needs to be updated.
        if(user) {
          setData({currentOrg: org, user: user, roles: user?.roles || [], orgs: user.organizations})
        }
      })
    },
    [setData],
  )

  const signIn = React.useCallback(
    form => authClient.signIn(form).then(user => {
      setData({user, currentOrg: user.organizations[0]})
      setOrg(user.organizations[0])
    }),
    [setData, setOrg],
  )
  const signUp = React.useCallback(
    form => authClient.signUp(form).then((data) => {
      let { organization, user } = data;
      setData({user, currentOrg: organization})
      setOrg(organization)
      signIn(form)
    }),
    [setData, setOrg, signIn],
  )
  const signOut = React.useCallback(() => {
    authClient.signOut()
    setData(null)
  }, [setData])

  const value = React.useMemo(() => ({user, signIn, signOut, signUp, roles, orgs, currentOrg, setOrg}), [
    signIn,
    signOut,
    signUp,
    user,
    roles,
    orgs,
    currentOrg,
    setOrg,
  ])

  if (isLoading || isIdle) {
    return <FullPageSpinner />
  }

  if (isError) {
    return <FullPageErrorFallback error={error} />
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />
  }

  throw new Error(`Unhandled status: ${status}`)
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export {AuthProvider, useAuth}
