import {queryCache} from 'react-query'

const localStorageKey = '__inven3_token__'

async function client(endpoint, {body, ...customConfig} = {}) {
  // Ignore this... It's the *only* thing we need to do thanks to the way we
  // handle fetch requests with the service worker. In your apps you shouldn't
  // need to have something like this.
  await window.__inven3_serverReady

  const token = window.localStorage.getItem(localStorageKey)
  const headers = {
    'content-type': 'application/json',
    'accept'      : 'application/json',
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }
  if (body) {
    config.body = JSON.stringify(body)
  }

  return window
    .fetch(currentURL(endpoint), config)
    .then(async r => {
      if (r.status === 401) {
        signOut()
        // refresh the page for them
        window.location.assign(window.location)
        return
      }

      if(config.method === 'DELETE') {
        return r;
      }

      let data = await r.json();
      // data = await r.json();
      if (r.ok) {
        if (r.url.endsWith('users/sign_in.json')) {
          const token = r.headers.get('Authorization').replace('Bearer ', '')
          return { token, user: data }
        } else {
          return data
        }
      } else {
        return Promise.reject(data)
      }
    })
}

function signOut() {
  queryCache.clear()
  window.localStorage.removeItem(localStorageKey);
  window.localStorage.removeItem('inven3-subdomain');
}

const currentURL = (endpoint) => {
  const subdomain = localStorage.getItem('inven3-subdomain');
  if (subdomain == null) return (`//${process.env.REACT_APP_API_URL}/${endpoint}`)
  return (`//${subdomain}.${process.env.REACT_APP_API_URL}/${endpoint}`)
}

const wsURL = () => {
  let protocol = 'wss:';
  if (window.location.protocol !== 'https:') {
    protocol = 'ws:';
  }
  const token = window.localStorage.getItem(localStorageKey)
  return `${protocol}${currentURL('cable')}?auth=${token}`;
}

const version = () => {
  return '1.1.1';
}

export {client, currentURL, localStorageKey, signOut, version, wsURL}
